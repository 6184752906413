import { $themeConfig } from '@themeConfig';
import useJwt from '@/auth/jwt/useJwt';
import { OriginType } from '@/libs/acl/config';

export default {
  namespaced: true,
  state: {
    filter_config: null,
    categories: null,
    product_detail: null,
    product_type_config: null,
  },
  getters: {
    getFilterConfig(state) {
      return state.filter_config;
    },
    getCategories(state) {
      return state.categories;
    },
    getProductDetail(state) {
      return state.product_detail;
    },
    getProductTypeConfig(state) {
      return state.product_type_config;
    },
  },
  mutations: {
    SET_FILTER_CONFIG(state, payload) {
      state.filter_config = payload;
    },
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    SET_PRODUCT_DETAIL(state, payload) {
      state.product_detail = payload;
      //Lưu dữ log vào localstorage
      try {
        let recent_products = localStorage.getItem('recent_products');
        if (!recent_products) {
          recent_products = [];
        } else {
          recent_products = JSON.parse(recent_products);
          if (recent_products.length > 7) {
            recent_products.splice(recent_products.length - 1, 1);
          }
        }
        if (recent_products.find((i) => i.id === payload.id) == undefined) {
          var x = JSON.parse(JSON.stringify(payload));
          x.description = '';
          recent_products.push(x);
        }
        localStorage.setItem(
          'recent_products',
          JSON.stringify(recent_products)
        );
      } catch (e) {
        localStorage.removeItem('recent_products');
      }
      //console.log(recent_products);
    },
    SET_PRODUCT_TYPE_CONFIG(state, payload) {
      state.product_type_config = payload;
    },
  },
  actions: {
    async fetchProducts({ commit }, vm) {
      var col_ = 3;
      if (vm.vw < 768) {
        col_ = 1;
      } else if (vm.vw < 1200) {
        col_ = 2;
      }
      console.log(col_);
      vm.loading(true);
      var filter = { onHome: 1 };
      if (localStorage.getItem('phoneWholeSale')) {
        filter['phoneWholeSale'] = localStorage.getItem('phoneWholeSale');
        filter['supplier'] = ["BASEUS"];
      }
      useJwt
        .post('products', {
          columnFilters: filter,
          sort: [],
          page: 1,
          pageSize: 15,
          lang: vm.$l,
        })
        .then((response) => {
          vm.products = [];
          var i = 0;
          response.data.result.forEach((m) => {
            m.images = JSON.parse(m.images);
            if (m.categories && m.categories.length > 0) {
              m.categories = JSON.parse(m.categories);
            }
            m.meta = JSON.parse(m.meta);
            m.variants = JSON.parse(m.variants);
            if (m.config && m.config.length > 0) {
              m.config = JSON.parse(m.config);
            }
            if (m.attributes && m.attributes.length > 0) {
              m.attributes = JSON.parse(m.attributes);
            }
            if (m.origin_type == OriginType.BASUES) {
              // m.variants.forEach(function (x, idx) {
              //   // x.price = Math.round((x.price + x.base_price) / (2 * 1000)) * 1000;
              // });

              m.image_url = m.images[0].src;
              var phoneWholeSale = localStorage.getItem('phoneWholeSale');
              if (phoneWholeSale) {
                m.price = Math.round(1.05 * m.variants[0].priceWoo / 1000) * 1000;
              }
              else {
                m.price = m.variants[0].price;
              }

              m.base_price = m.variants[0].base_price;
            }
            else {
              m.image_url = m.images[0].image_url;
            }
            var x;
            if ((i + 1) % col_ == 1 || col_ == 1) {
              x = [];
              vm.products.push(x);
            } else {
              x = vm.products[vm.products.length - 1];
            }
            i++;
            x.push(m);
          });
          vm.loading(false);
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },

    /**
     * bfd sửa lấy cả baseus để hiện thị chung trong bảng sản phảm
     * @param {*} param0 
     * @param {*} vm 
     */
    async fetchProductsHome({ commit }, vm) {
      vm.loading(true);
      if (!vm.serverParams.page) {
        vm.serverParams.page = 1;
      }
      if (!vm.serverParams.pageSize) {
        vm.serverParams.pageSize = 18;
      }
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      if (vm.sort && vm.sort.length > 0) {
        var arrSort = vm.sort.split('|');
        var sortPro = arrSort[0].trim();
        if (phoneWholeSale && phoneWholeSale.length > 0) {
          if (sortPro.indexOf('price') >= 0) {
            sortPro = sortPro.replace('price', 'priceWoo');
          }
        }
        vm.serverParams.sort = [{ 'field': sortPro, 'type': arrSort[1].trim() }];
      }

      if (phoneWholeSale) {
        vm.serverParams.columnFilters['phoneWholeSale'] = phoneWholeSale;
        vm.serverParams.columnFilters['supplier'] = ["BASEUS"];
      }

      useJwt
        .post('products', vm.serverParams)
        .then((response) => {
          vm.totalRecords = response.data.totalRecords;
          // vm.products = [];
          // vm.devices = [];
          response.data.result
            .forEach((m) => {
              m.images = JSON.parse(m.images);
              if (m.categories && m.categories.length > 0) {
                m.categories = JSON.parse(m.categories);
              }
              m.meta = JSON.parse(m.meta);
              m.variants = JSON.parse(m.variants);
              if (m.config && m.config.length > 0) {
                m.config = JSON.parse(m.config);
              }
              if (m.attributes && m.attributes.length > 0) {
                m.attributes = JSON.parse(m.attributes);
              }
              if (m.origin_type == OriginType.BASUES) {
                // m.variants.forEach(function (x, idx) {
                //   // x.price = Math.round((x.price + x.base_price) / (2 * 1000)) * 1000;
                // });

                m.image_url = m.images[0].src;

                var phoneWholeSale = localStorage.getItem('phoneWholeSale');
                if (phoneWholeSale) {
                  m.price = Math.round(1.05 * m.variants[0].priceWoo / 1000) * 1000;
                }
                else {
                  m.price = m.variants[0].price;
                }

                m.base_price = m.variants[0].base_price;
              }
              else {
                m.image_url = m.images[0].image_url;
              }
            });

          vm.products = vm.products.concat(response.data.result);
          vm.loading(false);
          console.log(vm.products);
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    /**
     * bfd bổ sung case baseus
     * @param {} param0 
     * @param {*} vm 
     */
    async fetchCategories({ commit }, vm) {
      var isCatLoading = false;
      if (!vm.isLoading) {
        isCatLoading = true;
        vm.loading(true);
      }

      useJwt
        .get('categories')
        .then((response) => {
          console.log('response.data.result: ', response.data.result);
          vm.lstCategories = response.data.result;
          commit('SET_CATEGORIES', response.data.result);
          if (isCatLoading) {
            vm.loading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (isCatLoading) {
            vm.loading(false);
          }
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    /**
     * bfd bổ sung luồng baseus
     * @param {} param0 
     * @param {*} vm 
     */
    async fetchProductCategory({ commit }, vm) {
      var isCatLoading = false;
      if (!vm.isLoading) {
        isCatLoading = true;
        vm.loading(true);
      }
      if (!vm.serverParams.page) {
        vm.serverParams.page = 1;
      }
      if (!vm.serverParams.pageSize) {
        vm.serverParams.pageSize = 9;
      }
      var phoneWholeSale = localStorage.getItem('phoneWholeSale');
      if (vm.sort && vm.sort.length > 0) {
        var arrSort = vm.sort.split('|');
        var sortPro = arrSort[0].trim();
        if (phoneWholeSale && phoneWholeSale.length > 0) {
          if (sortPro.indexOf('price') >= 0) {
            sortPro = sortPro.replace('price', 'priceWoo');
          }
        }
        vm.serverParams.sort = [{ 'field': sortPro, 'type': arrSort[1].trim() }];
      }

      if (phoneWholeSale) {
        vm.serverParams.columnFilters['supplier'] = ["BASEUS"];
        vm.serverParams.columnFilters['phoneWholeSale'] = phoneWholeSale;
      }

      /**
       * bfd: case nếu sửa luồng
       */
      useJwt
        .post('products', vm.serverParams)
        .then((response) => {
          // vm.products = []; 
          vm.totalRecords = response.data.totalRecords;
          console.log(response.data.result);
          if (isCatLoading) {
            vm.loading(false);
          }
          if (response.data.totalRecords == 0) {
            return;
          }
          response.data.result.forEach((m) => {
            m.images = JSON.parse(m.images);
            if (m.categories && m.categories.length > 0) {
              m.categories = JSON.parse(m.categories);
            }
            m.meta = JSON.parse(m.meta);
            m.variants = JSON.parse(m.variants);
            if (m.config && m.config.length > 0) {
              m.config = JSON.parse(m.config);
            }
            if (m.attributes && m.attributes.length > 0) {
              m.attributes = JSON.parse(m.attributes);
            }

            if (m.origin_type == OriginType.BASUES) {
              // m.variants.forEach(function (x, idx) {
              //   x.price = Math.round((x.price + x.base_price) / (2 * 1000)) * 1000;
              // });

              m.image_url = m.images[0].src;
              if (phoneWholeSale && phoneWholeSale.length > 0) {
                m.price = Math.round(1.05 * m.variants[0].priceWoo / 1000) * 1000;
              }
              else {
                m.price = m.variants[0].price;
              }

              m.base_price = m.variants[0].base_price;
            }
            else {
              m.image_url = m.images[0].image_url;
            }
            vm.products.push(m);
          });
        })
        .catch((err) => {
          console.log(err);
          if (isCatLoading) {
            vm.loading(false);
          }
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    /**
 * bfd bổ sung case baseus + tất cả
 * @param {} param0 
 * @param {*} vm 
 */
    async fetchFilterConfig({ commit }, vm) {
      var isCatLoading = false;
      if (!vm.isLoading) {
        isCatLoading = true;
        vm.loading(true);
      }
      var slug = vm.$route.params.Slug ? vm.$route.params.Slug : 'tat-ca';//k có thì lấy tất cả để k phải sửa url menu top
      useJwt
        .get('getFilterConfig/' + slug)
        .then((response) => {
          if (response.data.result && response.data.result.length > 0) {
            response.data.result.forEach(item => {
              vm.filterCheckeds[item.code] = [];
              if (item.children && item.children.length > 0) {
                item.children.forEach(chi => {
                  vm.filterCheckeds[chi.code] = [];
                });
              }
            });
          }
          commit('SET_FILTER_CONFIG', response.data.result);
          vm.loading(false);
        })
        .catch((err) => {
          console.log(err);
          if (isCatLoading) {
            vm.loading(false);
          }
          vm.$toast.error(
            'Có lỗi lấy filter trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    /**
 * bfd bổ sung case baseus
 * @param {} param0 
 * @param {*} vm 
 */
    async fetchProductDetail({ commit }, vm) {
      vm.loading(true);
      vm.isLoading = true;
      /**
       * bfd: case nếu orginType baseus  
       */
      useJwt
        .get('product-detail/' + vm.$route.params.Slug)
        .then((response) => {
          console.log('response.data.result: ', response.data.result);
          response.data.result.images = JSON.parse(
            response.data.result.images
          );

          if (response.data.result.categories && response.data.result.categories.length > 0) {
            response.data.result.categories = JSON.parse(
              response.data.result.categories
            );
          }
          if (response.data.result.meta && response.data.result.meta.length > 0) {
            response.data.result.meta = JSON.parse(response.data.result.meta);
          }
          if (response.data.result.variants && response.data.result.variants.length > 0) {
            response.data.result.variants = JSON.parse(
              response.data.result.variants
            );
          }
          if (response.data.result.config && response.data.result.config.length > 0) {
            response.data.result.config = JSON.parse(
              response.data.result.config
            );
          }
          if (response.data.result.attributes && response.data.result.attributes.length > 0) {
            response.data.result.attributes = JSON.parse(
              response.data.result.attributes
            );
          }
          if (response.data.result.origin_type == OriginType.BASUES) {
            // response.data.result.variants.forEach(function (x, idx) {
            //   // x.price = Math.round((x.price + x.base_price) / (2 * 1000)) * 1000;
            //   response.data.result.subDescription = x.wooProduct.description;
            // });
            response.data.result.images.forEach((im) => {
              im.image_url = im.src;
            });
            response.data.result.image_url = response.data.result.images[0].src;

            var opName = [];
            if (response.data.result.options && response.data.result.options.length > 0) {
              response.data.result.options = JSON.parse(response.data.result.options);
            }
            response.data.result.options.forEach((op) => {
              opName.push(op.name);
            });
            response.data.result.nameOption = opName.join('/');

            let variants = [];
            var phoneWholeSale = localStorage.getItem('phoneWholeSale');
            response.data.result.variants.forEach(function (x, idx) {
              //tính giá bán sỉ
              if (phoneWholeSale) {
                x.price = Math.round(1.05 * x.priceWoo / 1000) * 1000;
              }

              if (x.wooProduct && x.wooProduct.meta_data) {
                for (var i = 0; i < x.wooProduct.meta_data.length; i++) {
                  var oItem = x.wooProduct.meta_data[i];
                  if (oItem.key == 'kiotviet_quantity' && oItem.value > 0) {
                    x.quantity = oItem.value;
                    break;
                  }
                }
                if (x.quantity > 0) {
                  variants.push(x);
                }
              }
              if (!response.data.result.subDescription && x.wooProduct && x.wooProduct.description) {
                response.data.result.subDescription = x.wooProduct.description;
              }
            });
            if (!variants || variants.length == 0) {
              vm.$toast.error(
                'Sản phẩm hiện tại đã hết hàng trong kho xin quý khách hãy chọn sản phẩm khác. Xin lỗi vì sự bất tiện này!',
                { icon: true, closeButton: 'button' }
              );
              vm.loading(false);
              vm.isLoading = false;
              return;
            }

            response.data.result.price = response.data.result.variants[0].price;
            response.data.result.base_price = response.data.result.variants[0].base_price;
            response.data.result.sku = response.data.result.variants[0].sku;
            response.data.result.variants = variants;
          }
          else {
            response.data.result.image_url = response.data.result.images[0].image_url;
          }

          commit('SET_PRODUCT_DETAIL', response.data.result);
          vm.$store.dispatch('products/fetchProductTypeConfig', vm);
          setTimeout(() => {
            vm.loading(false);
            vm.isLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.isLoading = false;
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    /**
 * bfd bổ sung case baseus
 * @param {} param0 
 * @param {*} vm 
 */
    async fetchProductTypeConfig({ commit }, vm) {
      vm.loading(true);
      vm.isLoading = true;
      useJwt
        .get('product-type-config/' + vm.product_detail.product_type)
        .then((response) => {
          commit('SET_PRODUCT_TYPE_CONFIG', response.data.result);
          vm.loading(false);
          vm.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.isLoading = false;
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },
    async fetchSimData({ commit }, vm) {
      if (vm.$route.params.originType == 'baseus') {
        return;
      }

      vm.loading(true);

      useJwt
        .get('sim_search_sim_data/1')
        .then((response) => {
          if (response.data.result[0]) {
            vm.phone = response.data.result[0];
            vm.phone.product_type = 100;
          }
          vm.loading(false);
        })
        .catch((err) => {
          vm.loading(false);
          vm.$toast.error(
            'Có lỗi trong khi lấy SIM Data, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    },

    /**
     * bfd lấy sản phẩm khám phá khác
     */
    async getDiscover({ commit }, vm) {
      vm.isLoading = true;
      useJwt
        .get('product-discover')
        .then((response) => {
          if (response.status) {
            vm.productDiscover = response.data.result;
          }
          vm.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          vm.isLoading = false;
          vm.$toast.error(
            'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!',
            { icon: true, closeButton: 'button' }
          );
        });
    }
  },
};
