import axios from "axios";
import { $ctx } from '@themeConfig'

const initUserTracking = function () {

    // update user tracking of previous accessing time
    const updateUserActivities = function () {
      let userTrackData = JSON.parse(localStorage.getItem('userTrackData'))
      if(!(userTrackData && userTrackData.length)) userTrackData = []

      let temData = JSON.parse(JSON.stringify(userTrackData))

      console.log('temData', temData)

      userTrackData.forEach(item => {
        console.log('localStorage.nextLocalTrackId', localStorage.nextLocalTrackId)
        console.log('item.trackId', item.trackId)
        if(localStorage.nextLocalTrackId !== item.trackId) {
          axios.post(`${$ctx.API_CRM_URL}/modifyCookiesNoAuth`, item)
          .then(res => {
              temData = temData.filter(i => i.trackId.toString() !== item.trackId.toString())
              localStorage.setItem('userTrackData', JSON.stringify(temData))
          })
          .catch(error => {
              console.log(error)
          })
        }
      })
    }

    updateUserActivities();

    var events = ["mouseup"]//, "keydown", "scroll", "mousemove"];
    document.addEventListener('DOMContentLoaded', function () {

      // check local trackId should create new or re-use
      const trackDataForCheck = JSON.parse(localStorage.getItem('userTrackData'))
      if (trackDataForCheck && trackDataForCheck.length > 0){
        const item = trackDataForCheck.find(i => i.trackId === localStorage.nextLocalTrackId)
        if(item){
          localStorage.localTrackId = localStorage.nextLocalTrackId
        } else localStorage.localTrackId = Math.random().toString(36).slice(2);
      } else {
        localStorage.localTrackId = Math.random().toString(36).slice(2);
      }

      // agency setting
      const path = window.location.href
      const redirectFromPath = document.referrer
      let agency = 'Direct'
      if(redirectFromPath)
        agency = 'Organic'

      // reset data
      localStorage.isAcceptToSaveUserTracking = true
      localStorage.removeItem('nextLocalTrackId')
      localStorage.setItem('inputPath', path)
      localStorage.setItem('redirectFromPath', redirectFromPath)
      localStorage.removeItem('currentOrderId')
      localStorage.removeItem('userTrackStep')
      
      events.forEach(e => {
        document.addEventListener(e, (event) => {
          if (e === "mouseup"){
            let userTrackData = JSON.parse(localStorage.getItem('userTrackData'))
            if(!(userTrackData && userTrackData.length)) userTrackData = []
            
            let currentUserTrack = userTrackData.find(i => i.trackId === localStorage.localTrackId)
            console.log('event', event)

            let step = localStorage.userTrackStep
            if(!step)
              step = ''
    
            if(currentUserTrack) {
              if (event.target.nodeName === 'A') {          
                let dataItem = currentUserTrack.data.find(i => i.step.toString() === step.toString()) //find(i => i.url === event.target.href)
                if(dataItem) {
                  let urlClickItem = dataItem.urlClick.find(i => i.url === event.target.href)
                  if(urlClickItem){
                    urlClickItem.click++
                  } else {
                    urlClickItem = {
                      url: event.target.href,
                      click: 1
                    }
                    dataItem.urlClick.push(urlClickItem)
                  }
                } else {
                  dataItem = {
                    step: step,
                    agency: agency,
                    sim: null,
                    urlClick: [{
                      url: event.target.href,
                      click: 1
                    }]
                  }
                  currentUserTrack.data.push(dataItem)
                }
              } else if (event.target.nodeName === 'BUTTON') {
                if(event.target.id === 'payment-btn') {
                  localStorage.isAcceptToSaveUserTracking = false
                }
              }
            } else {
              currentUserTrack = {
                trackId: localStorage.localTrackId,
                orderId: localStorage.currentOrderId,
                data: [{
                  step: step,
                  agency: agency,
                  sim: null,
                  urlClick: [{
                    url: event.target.href,
                    click: 1
                  }]
                }]
              }
    
              userTrackData.push(currentUserTrack)
            }
    
            localStorage.setItem('userTrackData', JSON.stringify(userTrackData))
          }
        })
      })
    })

    window.addEventListener("beforeunload", function(e) {
      if(localStorage.isAcceptToSaveUserTracking.toString() === 'false') {
        localStorage.nextLocalTrackId = localStorage.localTrackId
      }
      updateUserActivities();
    });
}

export default initUserTracking