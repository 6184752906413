import Vue from 'vue'
import { ToastPlugin, ModalPlugin, } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import { $themeConfig } from '@themeConfig'
import en from './assets/i18n/en.json'
import vi from './assets/i18n/vi.json'

const messages = {
  en: en, vi: vi
}

var i18n = new VueI18n({
  locale: 'vi',
  messages
});

Object.defineProperty(Vue.prototype, '$locale', {
  get: function () {
    return i18n.locale
  },
  set: function (locale) {
    i18n.locale = locale
  }
})

Vue.prototype.loading = function (type) {
  if (type) {
    document.getElementById('loading-bg').style.display = "block";
  } else {
    document.getElementById('loading-bg').style.display = "none";
  }
}

Object.defineProperty(Vue.prototype, '$l', {
  get: function () {
    return i18n.locale == 'vi' ? 1 : 2;
  }
})

import router from './router'
import store from './store'
import App from './App.vue'

import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'

Vue.use(imagePreview)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#ed1f24',
  cancelButtonColor: '#ff7674',
};
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2, options)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

var numeral = require("numeral");

Vue.filter("number", function (value) {
  return numeral(value).format("0,0").replace(/,/g, ".");
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

import initUserTracking from './user-tracking'
initUserTracking()