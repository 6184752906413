import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        Slug: "about-itel",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/-/:id/:campaign_name",
      name: "short-link",
      component: () => import("@/views/ShortLink.vue"),
    },
    {
      path: "/san-pham/:msisdn",
      name: "san-pham",
      component: () => import("@/views/ProductInfo.vue"),
    },
    {
      path: "/gop-y",
      name: "gop-y",
      component: () => import("@/views/Feedback.vue"),
    },
    {
      path: "/thong-tin/:Slug",
      name: "ve-itel",
      component: () => import("@/views/StaticPage.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/info/:Slug",
      name: "about-itel",
      component: () => import("@/views/StaticPage.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/uu-dai-hot",
      name: "uu-dai-hot",
      component: () => import("@/views/HotDeal.vue"),
      meta: {
        pageTitle: "",
        Slug: "uu-dai-hot",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/hot-deal",
      name: "hot-deal",
      component: () => import("@/views/HotDeal.vue"),
      meta: {
        pageTitle: "",
        Slug: "hot-deal",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/uu-dai-hot/:Id",
      name: "uu-dai-hot-detail",
      component: () => import("@/views/HotDealDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/hot-deal/:Id",
      name: "hot-deal-detail",
      component: () => import("@/views/HotDealDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tin-tuc",
      name: "tin-tuc",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/i-news",
      name: "i-news",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tin-tuc/:Id",
      name: "tin-tuc-cat",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tin-tuc/:Id/xem-them/:Type",
      name: "tin-tuc-grid",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/i-news/:Id/view-more/:Type",
      name: "tin-tuc-grid",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/i-news/:Id",
      name: "i-news-cat",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },

    {
      path: "/tuyen-dung",
      name: "tuyen-dung",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tuyen-dung/:Id",
      name: "recruit-detail",
      component: () => import("@/views/RecruitDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },

    {
      path: "/recruit",
      name: "recruit",
      component: () => import("@/views/News.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tin-tuc/:categoryId/:Id",
      name: "tin-tuc-detail",
      component: () => import("@/views/NewsDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/i-news/:categoryId/:Id",
      name: "i-news-detail",
      component: () => import("@/views/NewsDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/dai-ly",
      name: "dai-ly",
      component: () => import("@/views/Store.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/diem-dich-vu",
      name: "diem-dich-vu",
      component: () => import("@/views/CustomerServicePoint.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/agency",
      name: "agency",
      component: () => import("@/views/Store.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/thu-vien-goi-cuoc",
      name: "thu-vien-goi-cuoc",
      component: () => import("@/views/DataPack.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/data-package-library",
      name: "data-package-library",
      component: () => import("@/views/DataPack.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/chien-dich/:CategorySlug",
      name: "chien-dich",
      component: () => import("@/views/Campaign.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/campaign/:CategorySlug",
      name: "campaign",
      component: () => import("@/views/Campaign.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/chien-dich/-/:Slug",
      name: "chien-dich",
      component: () => import("@/views/CampaignDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/campaign/-/:Slug",
      name: "campaign",
      component: () => import("@/views/CampaignDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/chien-dich/:CategorySlug/:Slug",
      name: "chien-dich",
      component: () => import("@/views/Campaign.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/campaign/:CategorySlug/:Slug",
      name: "campaign",
      component: () => import("@/views/Campaign.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ho-tro/thong-tin-nhan-sim",
      name: "thong-tin-nhan-sim",
      component: () => import("@/views/ChangeSim.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ho-tro/:Slug",
      name: "ho-tro-slug",
      component: () => import("@/views/Support.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/support/:Slug",
      name: "support-slug",
      component: () => import("@/views/Support.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/support/:Slug",
      name: "support-slug",
      component: () => import("@/views/Support.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/ho-tro",
      name: "ho-tro",
      component: () => import("@/views/Support.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/views/Support.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/dang-nhap",
      name: "dang-nhap",
      component: () => import("@/views/Login.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang",
      name: "mua-hang",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-sim-data",
      name: "mua-sim-data",
      component: () => import("@/views/B2CSimData.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      }
    },
    {
      path: '/dien-thoai-thiet-bi',
      name: 'products-home',
      component: () => import('@/views/shop/ProductHome.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dien-thoai-thiet-bi/:Slug',
      name: 'products',
      component: () => import('@/views/shop/ProductHome.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/danh-muc/:originType/:Slug',
    //   name: 'shop',
    //   component: () => import('@/views/shop/ProductCategory.vue'),
    //   meta: {
    //     pageTitle: '',
    //     breadcrumb: [
    //       {
    //         text: 'Itel',
    //         active: true,
    //       },
    //     ],
    //   },
    // }, 
    {
      path: '/danh-muc/:originType/:parentSlug/:Slug',
      name: 'shop',
      component: () => import('@/views/shop/ProductCategory.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/shop/san-pham/:originType/:slugPath/:Slug',
      name: 'product-detail',
      component: () => import('@/views/shop/ProductDetail.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    }, 
    {
      path: "/gio-hang-tra-gop",
      name: "gio-hang-tra-gop",
      component: () => import("@/views/InstallmentShoppingCart.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sim-tra-gop",
      name: "sim-tra-gop",
      component: () => import("@/views/InstallmentShoppingSim.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/gio-hang-va-thanh-toan",
      name: "gio-hang-va-thanh-toan",
      component: () => import("@/views/InstallmentCartCheckOut.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-sim-so-le/:id",
      name: "mua-sim-so-le",
      component: () => import("@/views/B2CSimData.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang/:step",
      name: "mua-hang-buoc",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang/hoan-thanh/:payment",
      name: "mua-hang-hoan-thanh",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang/hoan-thanh/:paymentResult/:orderId/:payment",
      name: "ket-thuc-mua-hang",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shop/mua-hang/:step",
      name: "shop-mua-hang-hoanthanh",
      component: () => import("@/views/shop/Payment.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang/buoc/:step",
      name: "mua-hang-step",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shipment/:id",
      name: "ve-itel",
      component: () => import("@/views/ShipmentTracking.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/shipment",
      name: "ve-itel",
      component: () => import("@/views/ShipmentTracking.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/club/voucher-detail/:Slug",
      name: "club/voucher-detail",
      component: () => import("@/views/ClubVoucherDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/club",
      name: "club",
      component: () => import("@/views/Club.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/club-blog/gioi-thieu/:Slug",
      name: "club-blog/gioi-thieu",
      component: () => import("@/views/ClubBlogDetail.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/club-blog",
      name: "club-blog",
      component: () => import("@/views/ClubBlog.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vietlot",
      name: "vietlot",
      component: () => import("@/views/Vietlot.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vietlott",
      name: "vietlott",
      component: () => import("@/views/Vietlot.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vpbank",
      name: "vpbank",
      component: () => import("@/views/VpBank.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/nhanuudai",
      name: "DonateMoney",
      component: () => import("@/views/DonateMoney.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/success-donate-money",
      name: "SuccessDonateMoney",
      component: () => import("@/views/SuccessDonateMoney.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vexere",
      name: "vexere",
      component: () => import("@/views/Vexere.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tima",
      name: "tima",
      component: () => import("@/views/shop/partner/Tima.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/dien-thoai-thiet-bi",
      name: "products",
      component: () => import("@/views/shop/ProductHome.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
     
    {
      path: "/shop/thanh-toan",
      name: "thanh-toan",
      component: () => import("@/views/shop/Payment.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/thong-tin-don-hang/:id",
      name: "thong-tin-don-hang",
      component: () => import("@/views/shop/OrderInfo.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sim-phong-thuy",
      name: "sim-phong-thuy",
      component: () => import("@/views/simphongthuy/Home.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/diem-phong-thuy",
      name: "diem-phong-thuy",
      component: () => import("@/views/simphongthuy/DiemPhongThuy.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/bo-loc-sim-phong-thuy",
      name: "sim-phong-thuy-boloc",
      component: () => import("@/views/simphongthuy/Category.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/abtrip",
      name: "abtrip",
      component: () => import("@/views/ABTrip.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vntrip",
      name: "vntrip",
      component: () => import("@/views/VNTrip.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: '/itshop/:slug',
      name: 'shop-home',
      component: () => import('@/views/shop/ShopHome.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/open-credit-card-vpbank',
      name: 'shop-home',
      component: () => import('@/views/shop/vpbank/OpenCreditCardVPBank.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotion-rule',
      name: 'shop-home',
      component: () => import('@/views/shop/vpbank/PromotionRules.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/installment-record',
      name: 'installment-record',
      component: () => import('@/views/InstallmentRecord.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-lookup',
      name: 'order-lookup',
      component: () => import('@/views/OrderLookup.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/RegisterInformation',
      name: 'register-information',
      component: () => import('@/views/RegisterInformation.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },{
      path: '/SuccessRegister/:slug/:phone',
      name: 'ket-thuc-dang-ky-thanh-cong',
      component: () => import('@/views/InformationSuccess.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },{
      path: '/FailRegister/:slug/:phone',
      name: 'ket-thuc-dang-ky-that-bai',
      component: () => import('@/views/InformationFail.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/UpdateSubscriberInformation',
      name: 'update-subscriber-information',
      component: () => import('@/views/UpdateSubscriberInformation.vue'),
      meta: {
        pageTitle: '',
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
