import { $themeConfig } from '@themeConfig';
import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {
    provinces: null,
    districts:null
  },
  getters: {
    getProvinces(state) {
      return state.provinces;
    },
    getDistricts(state) {
      return state.districts;
    }
  },
  mutations: {
    SET_PROVINCE(state, payload) {
      state.provinces = payload;
    },
    SET_DISTRICT(state, payload) {
      state.districts = payload;
    },
  },
  actions: {
    async fetchProvince({ commit }, vm) {
      vm.loading(true);
      useJwt.get('tima/get_province_tima').then((response) => {
          commit('SET_PROVINCE', response.data.result.sort((a,b) => a.provinceName > b.provinceName?1:-1));
          vm.loading(false);
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.$toast.error( 'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', { icon: true, closeButton: 'button' });
        });
    },
    async fetchDistrict({ commit }, vm) {
      vm.loading(true);
      if (vm.province_id != null) {
        useJwt.get('tima/get_district_tima/'+vm.province_id).then((response) => {
            commit('SET_DISTRICT', response.data.result.sort((a,b) => a.districtName > b.districtName?1:-1));
            vm.loading(false);
          })
          .catch((err) => {
            console.log(err);
            vm.loading(false);
            vm.$toast.error( 'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', { icon: true, closeButton: 'button' });
          });
      }else{
          commit('SET_DISTRICT', []);
          vm.loading(false);
      }
    },
    async createLoan({ commit }, vm) {
      vm.loading(true);
      useJwt.post('tima/create_loan_tima',{
        fullName:vm.item.full_name,
        phone:vm.item.phone,
        email:vm.item.email,
        provinceName:vm.provinces.find(i=>i.provinceId==vm.province_id).provinceName,
        districtName:vm.districts.find(i=>i.districtId==vm.district_id).districtName,
        provinceId:vm.province_id,
        districtId:vm.district_id,
        productId:vm.type,
        loanAmount:vm.packages.find(i=>i.id==vm.selected_package).long.replace(/,/g,''),
        period: vm.selected_period,
      }).then((response) => {
        //vm.$toast.success( 'Đã gửi yêu cầu đăng ký vay nhanh!', { icon: true, closeButton: 'button' });
        vm.loading(false);
        vm.submitted=true;
        vm.isFail=false;
        //setTimeout(function() {document.location='/tima'}, 5000);
        //vm.loading(false);
      })
        .catch((err) => {
          vm.isFail=true;
          vm.submitted=true;
          console.log(err);
          vm.loading(false);
          //vm.$toast.error( 'Có lỗi trong quá trình tạo yêu cầu vay nhanh: '+err.response.data.message+'!', { icon: true, closeButton: 'button' });
        });
    },
  },
};
