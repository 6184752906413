import { $themeConfig } from '@themeConfig';
import useJwt from '@/auth/jwt/useJwt';
import { OriginType } from '@/libs/acl/config';

export default {
  namespaced: true,
  state: {
    BoiSimBoloc: null,
    PhongThuySim:null,
    SPTSimDetail:null
  },
  getters: {
    getBoiSimBoloc(state) {
      return state.BoiSimBoloc;
    },
    getPhongThuySim(state) {
      return state.PhongThuySim;
    },
    getSPTSimDetail(state) {
      return state.SPTSimDetail;
    }
  },
  mutations: {
    SET_BoiSimBoloc(state, payload) {
      state.BoiSimBoloc = payload;
    },
    SET_PhongThuySim(state, payload) {
      state.PhongThuySim = payload;
    },
    SET_SPTSimDetail(state, payload) {
      state.SPTSimDetail = payload;
    }
  },
  actions: {
    async fetchBoiSimBoloc({ commit }, vm) {
      vm.loading(true);
      useJwt.get('BoiSimBoloc',{params:{menh:vm.menh,sim_type:vm.sim_type,dob:vm.day+"/"+vm.month+"/"+vm.year,page:vm.page,pageSize:vm.pageSize}}).then((response) => {
          commit('SET_BoiSimBoloc', response.data.result);
          vm.loading(false);
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.$toast.error( 'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', { icon: true, closeButton: 'button' });
        });
    },
    async fetchBoiSimBolo1({ commit }, p) {
      p.vm.isLoading = true;
      return useJwt.get('BoiSimBoloc',{params:{sim_type:p.sim_type,dob:p.dob,page:p.page,pageSize:p.pageSize}}).then((response) => {
          //commit('SET_BoiSimBoloc', response.data.result);
          return response.data.result;
        })
        .catch((err) => {
          console.log(err);
          p.vm.isLoading = false;
          p.vm.$toast.error( 'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', { icon: true, closeButton: 'button' });
          return null;
        });
    },
    async fetchPhongThuySim({ commit }, vm) {
      vm.loading(true);
      return useJwt.get('PhongThuySim',{params:{phone:vm.phone,dob:vm.day+"/"+vm.month+"/"+vm.year}}).then((response) => {
          commit('SET_PhongThuySim', response.data.result);
          response.data.result.phone = vm.phone;
          response.data.result.dob = vm.day+"/"+vm.month+"/"+vm.year;
          vm.loading(false);
          useJwt.post("sim_search",{columnFilters: {search: vm.phone},sort: [],page: 1,pageSize: 1}).then((r1) => {
            if (r1.data.result.length>0){
              response.data.result.pack = r1.data.result[0].Pack;
            }
            if (response.data.result.available) {
              if (localStorage.getItem('spt_recent')==null){
                var recent = [];
                recent.push(response.data.result);
                localStorage.setItem('spt_recent', JSON.stringify(recent));
              }else{
                var recent = JSON.parse(localStorage.getItem('spt_recent'));
                var found = false;
                for (var i = 0; i < recent.length; i++) {
                  if (recent[i].phone == response.data.result.phone) {
                    found = true;
                    break;
                  }
                }
                if (!found) {
                  if (recent.length>=5){
                    recent.splice(0,1);
                  }
                  recent.push(response.data.result);
                  localStorage.setItem('spt_recent', JSON.stringify(recent));
                }
              }
            }
          })
          
          return response.data.result;
        })
        .catch((err) => {
          console.log(err);
          vm.loading(false);
          vm.$toast.error( 'Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', { icon: true, closeButton: 'button' });
          return null;
        });
    },
    async fetchSPTSimDetail({ commit }, p) {
      p.vm.loading(true);
      return useJwt.post("sim_search",{columnFilters: {search: p.phone},sort: [],page: 1,pageSize: 1}).then((response) => {
          //commit('SET_SPTSimDetail', response.data.result);
          p.vm.loading(false);
          return response.data.result;
        }).catch((err) => {
          p.vm.loading(false);
          p.vm.$toast.error(
            "Có lỗi trong khi tìm kiếm số, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
          return null;
        });
    }
  },
};
