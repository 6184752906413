export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]
export const OriginType = {
  BASUES: 'baseus',
  OPPO:'oppo'
}
export const ProvinceIdConst= {
  HCM: 126 
}
export const _ = undefined
